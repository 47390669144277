
import React from 'react';
import { BsGithub } from 'react-icons/bs';


const SocialMedia = () => (
  <div className="app__social">
    <div>
    <BsGithub />
    </div>
   
  </div>
);

export default SocialMedia;